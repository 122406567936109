import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../types/redux";
import { PayloadAction } from "@reduxjs/toolkit";

import * as Sentry from "@sentry/browser";

export interface CardGenState {
	error?: string;
	status: RequestStatus;
	currentSessionId?: string;
	generatedCardText?: string;
	recipientLanguage?: string;
	translatedGeneratedCardText?: string;
}

export const initialState: CardGenState = {
	error: undefined,
	status: RequestStatus.IDLE,
	currentSessionId: localStorage.getItem("currentSessionId") || undefined,
};

export const cardGenReducers = {
	start: (
		state: CardGenState,
		{
			payload,
		}: PayloadAction<{
			recipientLanguage?: CardGenState["recipientLanguage"];
		}>
	) => {
		state.status = RequestStatus.LOADING;
		state.error = undefined;
		state.currentSessionId = undefined;
		state.generatedCardText = undefined;
		state.generatedCardText = undefined;
		state.recipientLanguage = payload.recipientLanguage;
	},

	startSameSession: (state: CardGenState) => {
		state.status = RequestStatus.LOADING;
		state.error = undefined;
		state.generatedCardText = undefined;
		state.generatedCardText = undefined;
	},

	success: (
		state: CardGenState,
		{
			payload,
		}: PayloadAction<{
			currentSessionId: CardGenState["currentSessionId"];
			generatedCardText: CardGenState["generatedCardText"];
			translatedGeneratedCardText: CardGenState["translatedGeneratedCardText"];
		}>
	) => {
		state.status = RequestStatus.COMPLETED;
		state.error = undefined;
		if (payload.currentSessionId) {
			state.currentSessionId = payload.currentSessionId;
		}
		if (payload.currentSessionId) {
			localStorage.setItem("currentSessionId", payload.currentSessionId);
		} else {
			localStorage.removeItem("currentSessionId");
		}
		state.generatedCardText = payload.generatedCardText;
		state.translatedGeneratedCardText = payload.translatedGeneratedCardText;
	},

	error: (
		state: CardGenState,
		{ payload }: PayloadAction<{ error: CardGenState["error"] }>
	) => {
		state.status = RequestStatus.FAILED;
		state.error = payload.error;

		Sentry.captureException(payload.error);
	},
};

export const cardgenSlice = createSlice({
	name: "cardgen",
	initialState,
	reducers: cardGenReducers,
});
