import { ISOLanguages } from "../content/translations/country_codes";

const mockCall = async (data: any) =>
	new Promise((resolve, reject) => {
		setTimeout(async () => {
			try {
				const response = await fetch("https://get.geojs.io/v1/ip");
				if (!response.ok) {
					throw `HTTP error! status: ${response.status}`;
				}
				resolve(data);
			} catch (e) {
				reject(e);
			}
		}, Math.random() * 2000);
	});

const apiUrl = (url: string) => {
	return `${process.env.REACT_APP_BACKEND_BASE_URL}${url}`;
};

export const fetchInitialLLM = async (
	senderName: string,
	recipientName: string,
	prompt: string,
	platformLanguage: string,
	recipientLanguage: string
) =>
	fetch(apiUrl("/generate_wish"), {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			language: {
				platform_language: (ISOLanguages as any)[platformLanguage].name,
				card_language: (ISOLanguages as any)[recipientLanguage].name,
			},
			request_data: {
				sender_name: senderName,
				receiver_name: recipientName,
				additional_notes: prompt,
			},
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			const { wish, session_id } = data;
			return Promise.resolve({
				sessionId: session_id,
				translatedGeneratedCardText:
					platformLanguage == recipientLanguage
						? undefined
						: wish.platform_language_message,
				generatedCardText: wish.card_language_message,
			});
		});

export const fetchTemplates = async (text: string, title_language: string) =>
	fetch(apiUrl(`/create_images/?title_language=${title_language}`), {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			text,
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			return Promise.resolve({ images: data.images_base64 });
		});

export const fetchFixCard = async (currentSessionId: string, prompt: string) =>
	fetch(apiUrl(`/edit_message/${currentSessionId}`), {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			prompt,
		}),
	})
		.then((response) => response.json())
		.then((data) => {
			const { edited_message, session_id } = data;
			return Promise.resolve({
				sessionId: session_id,
				translatedGeneratedCardText:
					edited_message.platform_language_message,
				generatedCardText: edited_message.card_language_message,
			});
		});
