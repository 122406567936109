export const translations = {
  "ai-disclaimer": {
    "sl": "Opomba: Klepetalnik NY Card Genie je razvit za ustvarjanje personaliziranih novoletnih voščilnic in se ne sme uporabljati za nobene druge namene. V skladu z Zakonom o umetni inteligenci je uporaba klepetalnika NY Card Genie in njegove vsebine strogo prepovedana za kakršno koli uporabo, ki je razvrščena kot visoko tvegana. <br/><br/> Ne pozabimo na Politiko podjetja glede uporabe umetne inteligence, ki zahteva transparentnost, varstvo podatkov in zaupnost, pravičnost in odgovornost. Več o politiki najdete na linku <a href=\"https://officepoint.nlbgroup.local/eVision.NLB.OfficePointOfficePointServices/activedocument/cedcf0b1-3ad6-487a-8268-1c8a9f45d51f\">politika podjetja</a>.<br/><br/> Pomembno je, da se zavedamo tveganj in zato ta orodja uporabljajmo previdno in pod pogojem, da ne razkrivamo kakršnih koli poslovnih in osebnih podatkov, ki niso javno dostopni. Pred potrditvijo vnosa besedil v ta orodja se prepričajte, da ne vsebujejo nobenih informacij, ki bi na kakršen koli način razkrivale kakršne koli poslovne, transakcijske ali osebne podatke in tudi ne kakršnih koli internih specifik delovanja banke. Informacije, ki so označen Zaupno ali Strogo zaupno, ne smejo biti predmet vnosa.<br/><br/><ol><li><a href=\"https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:52021AE2482\">Uredba (EU) 2024/1689 - Zakon o umetni inteligenci</a></li></ol>",
    "en": "Disclaimer: The NY Card Genie chatbot is designed for creating personalized New Year's greeting cards and must not be used for any other purposes. In accordance with the Artificial Intelligence Act, the use of the NY Card Genie chatbot and its content is strictly prohibited for any application classified as high-risk.<br/><br/>Let us not forget the Company Policy on the use of artificial intelligence, which requires transparency, data protection and confidentiality, fairness, and accountability. More information about the policy can be found at this link: <a href=\"https://officepoint.nlbgroup.local/eVision.NLB.OfficePointOfficePointServices/activedocument/cedcf0b1-3ad6-487a-8268-1c8a9f45d51f\">Company Policy</a>.<br/><br/>It is important to be aware of the risks and to use these tools cautiously, ensuring that no business or personal information not publicly accessible is disclosed. Before confirming text input into these tools, ensure that it does not contain any information that could, in any way, reveal business, transactional, or personal data, nor any internal specifications of the bank's operations. Information labeled as Confidential or Strictly Confidential must not be entered.<br/><br/><ol><li><a href=\"https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:52021AE2482\">Regulation (EU) 2024/1689 - Artificial Intelligence Act</a></li></ol>"
  },
  "ai-output-original": {
    "sl": "Generiran izvirnik",
    "en": "Generated original"
  },
  "ai-output-translated": {
    "sl": "Prevedeno v prejemnikov jezik",
    "en": "Translated to reciever's language"
  },
  "fix-card": {
    "sl": "Popravi voščilnico",
    "en": "Fix card"
  },
  "generate-card": {
    "sl": "Ustvari voščilnico",
    "en": "Generate card"
  },
  "index-title": {
    "sl": "New Year's Card Genie",
    "en": "New Year's Card Genie"
  },
  "instructions-for-prompt": {
    "sl": "Če želite ustvariti odličen poziv za kartico:\r\n\r\n<b>Bodite natančni</b>: Jasno opišite ton (npr. veselo, formalno) in temo (npr. novoletne želje, zaobljube).\r\n<b>Dodajte podrobnosti</b>: vključite informacije o prejemniku, kot je »rad hodi v hribe« ali »rad bere«. \r\n<b>Naj bo jedrnato</b>: izogibajte se dolgim navodilom, osredotočite se na ključne elemente. \r\n<b>Primer</b>: 'Napišite veselo novoletno sporočilo za prijatelja in omenite hvaležnost za njegovo podporo letos.'\r\n\r\nThe better the prompt, the better your card!",
    "en": "To create a great card prompt:\r\n\r\n<b>Be specific</b>: Clearly describe the tone (e.g., cheerful, formal) and theme (e.g., New Year's wishes, resolutions).\r\n<b>Add details</b>: Include information about the recipient, such as 'likes to hike the hills' or 'likes to read.' \r\n<b>Keep it concise</b>: avoid long instructions, focus on key elements. \r\n<b>Example</b>: 'Write a happy New Year's message for a friend and mention gratitude for their support this year.'\r\n\r\nThe better the prompt, the better your card!"
  },
  "intro-message": {
    "sl": "Ustvarite svojo edinstveno NLB personalizirano novoletno voščilnico za sodelavce in poslovne partnerje – hitro in enostavno. Vnesite ime prejemnika in nekaj ključnih vsebin, ki jih želite vključiti v sporočilo, nato pa prepustite umetni inteligenci, da oblikuje predlog besedila po vaših željah. Ko je besedilo popolno, izberite eno izmed številnih čudovitih vnaprej oblikovanih predlog. Na koncu preprosto prenesite svojo prilagojeno voščilnico in jo pošljite prek e-pošte, MS Teams ali Viva Engage.",
    "en": "Create your very own NLB personalized New Year greeting card for your colleagues and business partners in just a few steps. Start by providing the app with the name of your recipient and a couple of prompts you wish AI includes in the message. Once you are satisfied with the text AI suggests, you only have to choose from a variety of predesigned templates. Once you are satisfied, you can simply download your personalized card and send it by email, MS Teams or Viva Engage."
  },
  "ny-card-filename": {
    "sl": "novoletna_voščilnica",
    "en": "new_year_card"
  },
  "platform-back": {
    "sl": "Nazaj",
    "en": "Back"
  },
  "platform-continue": {
    "sl": "Nadaljuj",
    "en": "Continue"
  },
  "platform-copied-to-clipboard": {
    "sl": "Kopirano v odložišče!",
    "en": "Copied to clipboard!"
  },
  "platform-copy-to-clipboard": {
    "sl": "Kopiraj v odložišče",
    "en": "Copy to clipboard"
  },
  "platform-download": {
    "sl": "Prenesi",
    "en": "Download"
  },
  "platform-error": {
    "sl": "Napaka",
    "en": "Error"
  },
  "platform-language-select": {
    "sl": "Izberite jezik platforme",
    "en": "Choose platform language"
  },
  "platform-no-active-session": {
    "sl": "Ni aktivne seje.",
    "en": "No active session."
  },
  "platform-reset": {
    "sl": "Ponastavi",
    "en": "Reset"
  },
  "platform-try-again": {
    "sl": "Poskusite znova.",
    "en": "Try again."
  },
  "platform-understand": {
    "sl": "Razumem",
    "en": "I understand"
  },
  "platform-unkown-error": {
    "sl": "Neznana napaka.",
    "en": "Unknown error."
  },
  "prompt-for-fix": {
    "sl": "Bi želeli kaj spremeniti ali dodati? Prosim dodajte želje, hobije in kliknite Popravi, v nasprotnem primeru pa Nadaljujte.",
    "en": "Would you like to fix anything? Please let AI know and click Fix card; otherwise click Continue."
  },
  "prompt-message": {
    "sl": "Vnesite navodila za Umetno inteligenco (poziv)",
    "en": "Enter instructions for Artificial Intelligence (prompt)"
  },
  "recipient-language-select": {
    "sl": "Izberite jezik prejemnika",
    "en": "Choose recipient language"
  },
  "recipient-name": {
    "sl": "Ime prejemnika",
    "en": "Recipient name"
  },
  "select-template-desc": {
    "sl": "Izberi ozadje voščilnice in si jo prenesi s klikom na Kopiraj v odložišče ali Prenesi.",
    "en": "Select your final background by clicking on Copy to clipboard or Download."
  },
  "select-template-title": {
    "sl": "Izberi ozadje voščilnice",
    "en": "Select card background"
  },
  "sender-name": {
    "sl": "Ime pošiljatelja",
    "en": "Sender name"
  }
}