import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {}
export const PrimaryButton: FC<PropsWithChildren<ButtonProps>> = (props) => (
	<button
		{...props}
		className={classNames(
			"px-8 py-3 bg-primary text-white rounded-full font-bold disabled:bg-nlb_gray_lighter disabled:text-nlb_gray_medium whitespace-nowrap",
			{ "cursor-pointer": !props.disabled },
			props.className
		)}
	/>
);
export const SecondaryButton: FC<PropsWithChildren<ButtonProps>> = (props) => (
	<button
		{...props}
		className={classNames(
			"px-8 py-3 bg-secondary text-white rounded-full font-bold disabled:bg-nlb_gray_lighter disabled:text-nlb_gray_medium whitespace-nowrap",
			{ "cursor-pointer": !props.disabled },
			props.className
		)}
	/>
);
export const PrimaryButtonLink: FC<
	PropsWithChildren<LinkProps & { disabled?: boolean }>
> = (props) => (
	<Link
		{...props}
		className={classNames(
			"px-8 py-3 bg-primary text-white rounded-full font-bold disabled:bg-nlb_gray_lighter disabled:text-nlb_gray_medium whitespace-nowrap",
			{ "cursor-pointer": !props.disabled },
			props.className
		)}
	/>
);
