import classNames from "classnames";
import { FC, useEffect } from "react";

export const ErrorSummary: FC<{
	error?: string;
	className?: string;
}> = ({ error, className }) => {
	useEffect(() => {
		if (error) console.error(error);
	}, [error]);

	if (!error) return null;
	return (
		<div
			className={classNames(
				"bg-red-600 text-white p-2 m-2 rounded-md",
				className
			)}
		>
			{`${error}`}
		</div>
	);
};
