import classNames from "classnames";
import { FC, useState } from "react";

export interface DropdownItem {
	id?: string;
	text: string;
	iconSrc?: string;
}

/**
 * Dropdown component
 * @param options array of dropdown items, each item has a unique id and text.
 * If the dropdown is optional, the add an item with id undefined
 * @param onChange callback function that is called when the dropdown value changes
 * the item id is passed as an argument
 * @param value the id of the selected item
 */
export interface DropdownProps {
	options: DropdownItem[];
	onChange: (id: string | undefined) => any;
	value: string | undefined;
	className?: string;
	right?: boolean;
	label?: string;
	fullWidth?: boolean;
}

export const DefaultDropdown: FC<DropdownProps> = ({
	options,
	onChange,
	value,
	className,
	right,
	label,
	fullWidth,
}) => {
	const [open, setOpen] = useState(false);

	const listener = () => {
		setDropdownOpen(false);
	};

	const setDropdownOpen = (isOpen: boolean) => {
		if (isOpen) {
			setOpen(true);
			setTimeout(() => {
				window.addEventListener("click", listener);
			}, 100);
		} else {
			setOpen(false);
			window.removeEventListener("click", listener);
		}
	};

	if (!value && !options.find((item) => item.id === value)) {
		console.error(
			"If the dropdown is optional, add an item with id undefined"
		);
	}

	const selectedItem = options.find((item) => item.id === value);

	return (
		<>
			{label && (
				<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
					{label}
				</p>
			)}
			<div
				className={classNames("relative text-left", className, {
					"inline-block": !fullWidth,
				})}
			>
				<button
					type="button"
					className="inline-flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
					id="menu-button"
					aria-expanded="true"
					aria-haspopup="true"
					onClick={() => setDropdownOpen(!open)}
				>
					{selectedItem?.iconSrc && (
						<img
							src={selectedItem.iconSrc}
							alt={`option-${selectedItem.id}-icon`}
							className="w-5 h-5 -ml-1 rounded"
						/>
					)}
					<span className="flex-shrink flex-grow whitespace-nowrap overflow-hidden text-left">
						{
							value
								? selectedItem?.text
								: "Select an option" /* This should not happen */
						}
					</span>
					<svg
						className="-mr-1 size-5 text-gray-400 flex-shrink-0"
						viewBox="0 0 20 20"
						fill="currentColor"
						aria-hidden="true"
						options-slot="icon"
					>
						<path
							fillRule="evenodd"
							d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z"
							clipRule="evenodd"
						/>
					</svg>
				</button>
				{open && (
					<div
						className={classNames(
							"min-w-full absolute overflow-hidden z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none hover:cursor-pointer",
							{ "left-0": right, "right-0": !right }
						)}
						role="menu"
						aria-orientation="vertical"
						aria-labelledby="menu-button"
						tabIndex={-1}
					>
						<div className="" role="none">
							{options.map((item) => (
								<div
									key={item.id}
									className={`px-4 py-2 text-sm flex flex-row ${
										item.id === value
											? "bg-gray-100 text-gray-900 outline-none"
											: "text-gray-700"
									}`}
									tabIndex={-1}
									onClick={() => {
										onChange(item.id);
										setDropdownOpen(false);
									}}
								>
									{item.iconSrc && (
										<img
											src={item.iconSrc}
											alt={`option-${item.id}-icon`}
											className="w-5 h-5 -ml-2 mr-2 rounded"
										/>
									)}
									{item.text}
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
