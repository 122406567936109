import { ISOLanguages } from "../content/translations/country_codes";
import { translations } from "../content/translations/translations";

export type TranslationKey = keyof typeof translations;
export type TranslationLang = keyof (typeof translations)[TranslationKey];

/**
 * Get a translator function for a specific language
 * @param lang - language to translate to
 * @returns a function that takes a translation key and returns the translation
 * @example const t = getTranslator("sl"); t("page-title-1"); // "Domov"
 */
export const getTranslator =
	(lang: TranslationLang) => (key: TranslationKey) => {
		return translations[key][lang];
	};

export default {
	ISOLanguages,
};
