import { getTranslator } from "../../helpers/translation-provider";
import { RootState } from "../store";

const langTranslatorMemo = new Map<string, any>();
export const selectTranslator = ({ language }: RootState) => {
	// memo for react reasons
	const lang = language.platformLanguage;
	if (!langTranslatorMemo.has(lang)) {
		langTranslatorMemo.set(lang, getTranslator(lang));
	}
	return langTranslatorMemo.get(lang);
};
