import { combineReducers } from "redux";
import { cardgenSlice } from "./cardgen/cardgen.slice";
import { languageSlice } from "./language/language.slice";
import { templateSelectSlice } from "./template-select/template-select.slice";

export const rootReducer = combineReducers({
	[cardgenSlice.name]: cardgenSlice.reducer,
	[languageSlice.name]: languageSlice.reducer,
	[templateSelectSlice.name]: templateSelectSlice.reducer,
});
