import { FC, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { selectTranslator } from "../redux/language/language.selector";
import { BasicCard } from "../components/BasicCard";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import {
	HTMLInputField,
	HTMLInputFieldWithButton,
	HTMLTextAreaField,
} from "../components/InputFields";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import { RequestStatus } from "../types/redux";
import { RippleLoader } from "../components/LoadingIndicators";
import { ErrorSummary } from "../components/Errors";
import { getTextOnTemplates } from "../redux/template-select/template-select.actions";
import { fixCard } from "../redux/cardgen/cardgen.actions";
import { cardgenSlice } from "../redux/cardgen/cardgen.slice";

export const CardImprovement: FC = () => {
	const t = useAppSelector(selectTranslator);
	const cardgenState = useAppSelector((state) => state.cardgen);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data: any = Object.fromEntries(
			new FormData(e.target as HTMLFormElement)
		);
		if (data["prompt-for-fix"]) {
			dispatch(fixCard(data["prompt-for-fix"]));
		}
	};
	const promptForFixRef = useRef<HTMLInputElement>(null);

	// Clear the input box if the card fix is successful
	const previousState = useRef(cardgenState.status);
	useEffect(() => {
		if (
			previousState.current == RequestStatus.LOADING &&
			cardgenState.status == RequestStatus.COMPLETED
		) {
			if (promptForFixRef.current) promptForFixRef.current.value = "";
		}
		previousState.current = cardgenState.status;
	}, [cardgenState.status]);

	const [initialCardgenState, setInitialCardgenState] = useState(
		cardgenState?.generatedCardText
	);

	const generatedCardTextInputRef = useRef<HTMLDivElement>(null);
	if (
		!(
			cardgenState?.translatedGeneratedCardText &&
			cardgenState?.generatedCardText
		) &&
		cardgenState.status == RequestStatus.IDLE
	) {
		return <Navigate to="/initial-prompt" />;
	}

	const twoOutputLayout =
		cardgenState?.translatedGeneratedCardText &&
		cardgenState?.generatedCardText;

	return (
		<div>
			<BasicCard className="container">
				<div>
					<Link
						to="/initial-prompt"
						className="mb-10 inline-flex flex-row items-center gap-3"
					>
						<FaArrowLeft /> <div>{t("platform-back")}</div>
					</Link>
				</div>
				<div className="row mb-10">
					{cardgenState.status == RequestStatus.LOADING && (
						<div className="flex flex-row justify-center py-2">
							<RippleLoader />
						</div>
					)}
					{cardgenState.status != RequestStatus.LOADING &&
						(twoOutputLayout ? (
							<>
								<div className="col-12 sm:col flex flex-col mb-6 sm:mb-0">
									<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
										{t("ai-output-translated")}
									</p>
									<div className="flex-grow whitespace-pre-wrap block p-5 mb-6 w-full ring-1 ring-nlb_gray_lighter ring-inset rounded-lg shadow-lg font-semibold">
										{
											cardgenState.translatedGeneratedCardText
										}
									</div>
								</div>
								<div className="col-12 sm:col flex flex-col">
									<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
										{t("ai-output-original")}
									</p>
									<div
										contentEditable
										ref={generatedCardTextInputRef}
										onBlur={() => {
											dispatch(
												cardgenSlice.actions.success({
													currentSessionId:
														cardgenState.currentSessionId,
													generatedCardText: (
														generatedCardTextInputRef.current as HTMLDivElement
													).innerText,
													translatedGeneratedCardText:
														cardgenState.translatedGeneratedCardText,
												})
											);
										}}
										className="flex-grow whitespace-pre-wrap block p-5 mb-6 w-full ring-1 ring-nlb_gray_lighter ring-inset rounded-lg shadow-lg font-semibold"
									>
										{cardgenState.generatedCardText}
									</div>
								</div>
							</>
						) : (
							cardgenState.generatedCardText && (
								<div className="col flex flex-col">
									<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
										{t("ai-output-original")}
									</p>
									<div
										contentEditable
										ref={generatedCardTextInputRef}
										onBlur={() => {
											dispatch(
												cardgenSlice.actions.success({
													currentSessionId:
														cardgenState.currentSessionId,
													generatedCardText: (
														generatedCardTextInputRef.current as HTMLDivElement
													).innerText,
													translatedGeneratedCardText:
														cardgenState.translatedGeneratedCardText,
												})
											);
										}}
										className="flex-grow whitespace-pre-wrap block p-5 mb-6 w-full ring-1 ring-nlb_gray_lighter ring-inset rounded-lg shadow-lg font-semibold"
									>
										{cardgenState.generatedCardText}
									</div>
								</div>
							)
						))}
					{cardgenState.status == RequestStatus.FAILED && (
						<div className="col-12">
							<ErrorSummary
								error={cardgenState.error}
								className="m-0"
							/>
						</div>
					)}
				</div>
				<form onSubmit={onSubmitForm}>
					<div className="mb-10">
						<HTMLInputFieldWithButton
							label={t("prompt-for-fix")}
							name="prompt-for-fix"
							buttonText={t("fix-card")}
							elementProps={
								{ required: true, ref: promptForFixRef } as any
							}
						/>
					</div>
				</form>
				<div className="flex flex-row justify-end">
					<PrimaryButton
						disabled={!cardgenState.generatedCardText}
						onClick={() => {
							if (cardgenState.generatedCardText) {
								dispatch(
									getTextOnTemplates(
										cardgenState.generatedCardText
									)
								);
								navigate("/choose-template");
							}
						}}
					>
						{t("platform-continue")}
					</PrimaryButton>
				</div>
			</BasicCard>
		</div>
	);
};
