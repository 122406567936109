import { createSlice } from "@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { TranslationLang } from "../../helpers/translation-provider";

export interface LanguageState {
	platformLanguage: TranslationLang;
}

export const initialState: LanguageState = {
	platformLanguage: (localStorage.getItem("platformLanguage") as any) || "en",
};

export const languageReducers = {
	setPlatformLanguage: (
		state: LanguageState,
		{ payload }: PayloadAction<LanguageState["platformLanguage"]>
	) => {
		localStorage.setItem("platformLanguage", payload);
		state.platformLanguage = payload;
	},
};

export const languageSlice = createSlice({
	name: "language",
	initialState,
	reducers: languageReducers,
});
