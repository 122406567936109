import React from "react";
import { PrimaryButton } from "./components/Buttons";

import { store, useAppSelector } from "./redux/store";
import { Provider } from "react-redux";
import { Index } from "./screens";

import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Header } from "./components/Header";
import { InitialPrompt } from "./screens/initial-prompt";
import { CardImprovement } from "./screens/card-improvement";
import { ChooseTemplate } from "./screens/choose-template";
import { Toaster } from "react-hot-toast";
import { DebugScreen } from "./screens/debug";
import { ThrowError } from "./screens/throw-error";
import { BuildVersion } from "./screens/build-version";

function App() {
	return (
		<Router>
			<Provider store={store}>
				<Toaster position="bottom-right" />
				<div className="h-svh flex flex-col bg-nlb_gray_lighter">
					<Header />
					<div className="overflow-auto flex-grow flex-shrink md:m-6">
						<Routes>
							<Route index element={<Index />} />
							<Route
								path="/initial-prompt"
								element={<InitialPrompt />}
							/>
							<Route
								path="/card-improvement"
								element={<CardImprovement />}
							/>
							<Route
								path="/choose-template"
								element={<ChooseTemplate />}
							/>
							<Route
								path="/secret-debug"
								element={<DebugScreen />}
							/>
							<Route
								path="/throw-error"
								element={<ThrowError />}
							/>
							<Route
								path="/build-version"
								element={<BuildVersion />}
							/>
						</Routes>
					</div>
				</div>
			</Provider>
		</Router>
	);
}

export default App;
