import classNames from "classnames";
import { FC } from "react";
import { ImSpinner5 } from "react-icons/im";

export const DefaultLoading = () => (
	<ImSpinner5 className="animate-spin w-10 h-10 text-nlb_gray_darker" />
);

export const DefaultLoadingWithText = ({ text }: { text: string }) => (
	<div className="flex justify-center flex-col items-center mt-20">
		<DefaultLoading />
		<p className="text-lg ml-2">{text}</p>
	</div>
);

interface LoadingPageProps {
	marginTop?: boolean;
}
export const DefaultLoadingPage: FC<LoadingPageProps> = ({ marginTop }) => (
	<div
		className={classNames("flex justify-center items-center", {
			"mt-20": marginTop,
		})}
	>
		<DefaultLoading />
	</div>
);

export const RippleLoader: FC = () => {
	return (
		<div className="loadingio-spinner-ripple-2by998twmg8">
			<div className="ldio-yzaezf3dcmj">
				<div></div>
				<div></div>
			</div>
		</div>
	);
};
