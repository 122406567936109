import { createSlice } from "@reduxjs/toolkit";

import { RequestStatus } from "../../types/redux";
import { PayloadAction } from "@reduxjs/toolkit";

export interface TemplateSelectState {
	error?: string;
	status: RequestStatus;
	images?: string[];
}

export const initialState: TemplateSelectState = {
	error: undefined,
	status: RequestStatus.IDLE,
};

export const templateSelectReducers = {
	start: (state: TemplateSelectState) => {
		state.status = RequestStatus.LOADING;
		state.error = undefined;
	},

	success: (
		state: TemplateSelectState,
		{ payload }: PayloadAction<{ images: TemplateSelectState["images"] }>
	) => {
		state.status = RequestStatus.COMPLETED;
		state.error = undefined;
		state.images = payload.images;
	},

	error: (
		state: TemplateSelectState,
		{ payload }: PayloadAction<{ error: TemplateSelectState["error"] }>
	) => {
		state.status = RequestStatus.FAILED;
		state.error = payload.error;
	},
};

export const templateSelectSlice = createSlice({
	name: "templateSelect",
	initialState,
	reducers: templateSelectReducers,
});
