import { PrimaryButton } from "../components/Buttons";

export const ThrowError = () => (
	<PrimaryButton
		onClick={() => {
			throw new Error("This is your first error!");
		}}
	>
		Break the world
	</PrimaryButton>
);
