import { FC } from "react";
import { useAppSelector } from "../redux/store";
import { BasicCard } from "../components/BasicCard";
import { selectTranslator } from "../redux/language/language.selector";
import { getTranslator } from "../helpers/translation-provider";
import { RequestStatus } from "../types/redux";
import { RippleLoader } from "../components/LoadingIndicators";
import { Link, Navigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import toast from "react-hot-toast";
import { FaArrowLeft } from "react-icons/fa";
import { ErrorSummary } from "../components/Errors";

const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
	console.log(b64Data, contentType);
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

export const ChooseTemplate: FC = () => {
	const platformLanguage = useAppSelector(
		(state) => state.language.platformLanguage
	);
	const templateSelectState = useAppSelector((state) => state.templateSelect);
	const t = getTranslator(platformLanguage);
	if (templateSelectState.status == RequestStatus.IDLE) {
		return <Navigate to="/initial-prompt" />;
	}
	if (templateSelectState.status == RequestStatus.LOADING) {
		return (
			<div className="h-full flex flex-row justify-center">
				<RippleLoader />
			</div>
		);
	}
	if (templateSelectState.status == RequestStatus.FAILED) {
		return (
			<div className="m-6 md:m-0">
				<Link
					to="/card-improvement"
					className="mb-6 inline-flex flex-row items-center gap-3"
				>
					<FaArrowLeft /> <div>{t("platform-back")}</div>
				</Link>
				<h1 className="text-3xl mb-2 sm:mx-0">
					{t("select-template-title")}
				</h1>
				<p className="mb-6">{t("select-template-desc")}</p>
				<ErrorSummary
					error={templateSelectState.error}
					className="mb-6"
				/>
			</div>
		);
	}
	return (
		<>
			<div className="m-6 md:m-0">
				<Link
					to="/card-improvement"
					className="mb-6 inline-flex flex-row items-center gap-3"
				>
					<FaArrowLeft /> <div>{t("platform-back")}</div>
				</Link>
				<h1 className="text-3xl mb-2 sm:mx-0">
					{t("select-template-title")}
				</h1>
				<p className="mb-6">{t("select-template-desc")}</p>
			</div>
			<div className="flex flex-row gap-10 flex-wrap items-center justify-center">
				{templateSelectState.images?.map((image, index) => (
					<div
						key={index}
						className="shadow-lg bg-white rounded-2xl p-6"
					>
						<img
							src={image}
							alt={`template ${index}`}
							className="rounded-xl max-w-[500px]"
						/>

						<div className="flex flex-row justify-between mt-6">
							<PrimaryButton
								onClick={() => {
									try {
										const blob = b64toBlob(
											image.split(",")[1],
											"image/png"
										);
										console.log(blob);
										navigator.clipboard.write([
											new ClipboardItem({
												"image/png": blob,
											}),
										]);
										toast.success(
											t("platform-copied-to-clipboard")
										);
									} catch (error: any) {
										toast.error(
											error?.toString() ||
												t("platform-error")
										);
									}
								}}
							>
								{t("platform-copy-to-clipboard")}
							</PrimaryButton>
							<SecondaryButton
								onClick={() => {
									try {
										const blob = b64toBlob(
											image.split(",")[1],
											"image/png"
										);
										const blobUrl =
											URL.createObjectURL(blob);

										const link =
											document.createElement("a");
										link.href = blobUrl;
										link.download =
											t("ny-card-filename") + ".png";
										link.click();

										URL.revokeObjectURL(blobUrl);
									} catch (error) {
										toast.error(
											error?.toString() ||
												t("platform-error")
										);
									}
								}}
							>
								{t("platform-download")}
							</SecondaryButton>
						</div>
					</div>
				))}
			</div>
		</>
	);
};
