import { fetchTemplates } from "../api-helpers";
import { TypedThunk } from "../store";
import { templateSelectSlice } from "./template-select.slice";
import * as Sentry from "@sentry/browser";
const { actions } = templateSelectSlice;

export const getTextOnTemplates =
	(text: string): TypedThunk =>
	(dispatch, getState) => {
		dispatch(actions.start());
		const recipientLanguage = getState().cardgen.recipientLanguage;

		fetchTemplates(text, recipientLanguage == "sl" ? "sl" : "en")
			.then((result) => {
				dispatch(actions.success(result as any));
			})
			.catch((error) => {
				Sentry.captureException(error);
				dispatch(actions.error({ error: (error || "").toString() }));
			});
	};
