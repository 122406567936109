import React from "react";
import { PrimaryButtonLink } from "../components/Buttons";

import { useAppDispatch, useAppSelector } from "../redux/store";
import { selectTranslator } from "../redux/language/language.selector";
import { LanguageDropdown } from "../components/LanguageDropdowns";
import { BasicCard } from "../components/BasicCard";

export const Index = () => {
	// make this a decorator or selector
	const t = useAppSelector(selectTranslator);

	const dispatch = useAppDispatch();

	return (
		<BasicCard className="container max-w-[800px]">
			<div className="row mb-10">
				<div className="col">
					<h1 className="text-4xl mb-10">{t("index-title")}</h1>
					<div className="pb-2">{t("platform-language-select")}</div>
					<LanguageDropdown />
				</div>
			</div>
			<div className="mb-10 text-justify">{t("intro-message")}</div>
			<div
				className="text-nlb_gray_dark text-justify html-translation"
				dangerouslySetInnerHTML={{ __html: t("ai-disclaimer") }}
			></div>
			<div className="row">
				<div className="col text-nlb_gray_dark"></div>
				<div className="col flex flex-row justify-end">
					<div className="flex flex-col justify-center">
						<PrimaryButtonLink to={"/initial-prompt"}>
							{t("platform-understand")}
						</PrimaryButtonLink>
					</div>
				</div>
			</div>
		</BasicCard>
	);
};
