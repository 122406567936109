import { FC, InputHTMLAttributes, TextareaHTMLAttributes } from "react";
import { SecondaryButton } from "./Buttons";

interface HTMLInputFieldProps<T> {
	label: string;
	type?: string;
	name: string;
	placeholder?: string;
	elementProps?: T;
}

export const HTMLInputField: FC<
	HTMLInputFieldProps<InputHTMLAttributes<HTMLInputElement>>
> = ({ label, name, type = "text", elementProps }) => {
	return (
		<div>
			<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
				{label}
			</p>
			<input
				name={name}
				type={type}
				{...elementProps}
				className="block py-2 px-3 mb-6 w-full ring-1 ring-gray-300 ring-inset rounded-md shadow-sm font-semibold"
			/>
		</div>
	);
};

interface HTMLInputFieldWithButtonProps
	extends HTMLInputFieldProps<InputHTMLAttributes<HTMLInputElement>> {
	buttonText: string;
	buttonOnClick?: () => void;
}

export const HTMLInputFieldWithButton: FC<HTMLInputFieldWithButtonProps> = ({
	label,
	name,
	type = "text",
	elementProps,
	buttonText,
	buttonOnClick,
}) => {
	return (
		<div>
			<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
				{label}
			</p>
			<div className="flex flex-col sm:flex-row sm:items-center items-end gap-6 mb-6">
				<input
					name={name}
					type={type}
					{...elementProps}
					className="flex-grow block py-3 h-full px-3 w-full ring-1 ring-gray-300 ring-inset rounded-md shadow-sm font-semibold"
				/>
				<SecondaryButton onClick={buttonOnClick}>
					{buttonText}
				</SecondaryButton>
			</div>
		</div>
	);
};

export const HTMLTextAreaField: FC<
	HTMLInputFieldProps<TextareaHTMLAttributes<HTMLTextAreaElement>>
> = ({ label, name, elementProps }) => {
	return (
		<div>
			<p className="text-sm font-normal text-nlb_gray_dark pb-1.5">
				{label}
			</p>
			<textarea
				name={name}
				{...elementProps}
				className="block py-2 px-3 mb-6 w-full ring-1 ring-gray-300 ring-inset rounded-md shadow-sm font-semibold"
			/>
		</div>
	);
};
