import classNames from "classnames";
import { FC } from "react";

interface BasicCardProps {
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
}
export const BasicCard: FC<BasicCardProps> = ({
	children,
	className,
	style,
}) => (
	<div
		className={classNames(
			"md:shadow-lg bg-white relative overflow-visible sm:rounded-2xl p-6",
			className
		)}
		style={style}
	>
		{children}
	</div>
);
