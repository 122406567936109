import {
	getLangIconLocation,
	ISOLanguages,
} from "../content/translations/country_codes";
import { selectTranslator } from "../redux/language/language.selector";
import { languageSlice } from "../redux/language/language.slice";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { AVAILABLE_PLATFORM_LANGUAGES } from "../types/translations";
import { DefaultDropdown } from "./Dropdowns";

export const LanguageDropdown = () => {
	const platformLanguage = useAppSelector(
		(state) => state.language.platformLanguage
	);
	const dispatch = useAppDispatch();

	return (
		<DefaultDropdown
			options={AVAILABLE_PLATFORM_LANGUAGES.map((lang) => ({
				id: lang,
				text: (ISOLanguages as any)[lang].nativeName,
				iconSrc: getLangIconLocation(lang),
			}))}
			value={platformLanguage}
			onChange={(e) =>
				dispatch(languageSlice.actions.setPlatformLanguage(e as any))
			}
			right
		/>
	);
};
