import { nlbLogo } from "../content/icons";

export const Header = () => {
	return (
		<header className="flex-row pb-4 shadow-md bg-white md:border-none border-b-nlb_gray_light border-b-2">
			<div className="flex-row pl-3 pt-4">
				<img src={nlbLogo} className="h-10 pl-3" />
			</div>
		</header>
	);
};
