import { TypedThunk } from "../store";
import { cardgenSlice } from "./cardgen.slice";
import { fetchFixCard, fetchInitialLLM } from "../api-helpers";
import { getTranslator } from "../../helpers/translation-provider";

const { actions } = cardgenSlice;

export const startGenerateCard =
	(
		senderName: string,
		recipientName: string,
		prompt: string,
		recipientLanguage: string
	): TypedThunk =>
	(dispatch, getState) => {
		dispatch(
			actions.start({
				recipientLanguage,
			})
		);
		const platformLang = getState().language.platformLanguage;
		const recipientLang = recipientLanguage;
		const t = getTranslator(getState().language.platformLanguage);

		fetchInitialLLM(
			senderName,
			recipientName,
			prompt,
			platformLang,
			recipientLang
		)
			.then((result: any) => {
				dispatch(
					actions.success({
						currentSessionId: result.sessionId,
						generatedCardText: result.generatedCardText,
						translatedGeneratedCardText:
							result.translatedGeneratedCardText,
					})
				);
			})
			.catch((error) => {
				dispatch(
					actions.error({
						error: error || t("platform-unkown-error").toString(),
					})
				);
			});
	};

export const fixCard =
	(prompt: string): TypedThunk =>
	(dispatch, getState) => {
		dispatch(actions.startSameSession());
		const currentSessionId = getState().cardgen.currentSessionId;

		const t = getTranslator(getState().language.platformLanguage);

		if (!currentSessionId) {
			dispatch(actions.error({ error: t("platform-no-active-session") }));
			return;
		}

		return fetchFixCard(currentSessionId, prompt)
			.then((result: any) => {
				dispatch(
					actions.success({
						currentSessionId: result.sessionId,
						generatedCardText: result.generatedCardText,
						translatedGeneratedCardText: getState().cardgen
							.translatedGeneratedCardText
							? result.translatedGeneratedCardText
							: undefined,
					})
				);
			})
			.catch((error) => {
				dispatch(
					actions.error({
						error: error || t("platform-unkown-error").toString(),
					})
				);
			});
	};
