export const ISOLanguages = {
	ab: {
		family: "Northwest Caucasian",
		name: "Abkhaz",
		nativeName: "Аҧсуа бызшәа, аҧсшәа",
		"639-1": "ab",
		"639-2": "abk",
		"639-2/B": "",
	},
	aa: {
		family: "Afro-Asiatic",
		name: "Afar",
		nativeName: "Afaraf",
		"639-1": "aa",
		"639-2": "aar",
		"639-2/B": "",
	},
	af: {
		family: "Indo-European",
		name: "Afrikaans",
		nativeName: "Afrikaans",
		"639-1": "af",
		"639-2": "afr",
		"639-2/B": "",
	},
	ak: {
		family: "Niger–Congo",
		name: "Akan",
		nativeName: "Akan",
		"639-1": "ak",
		"639-2": "aka",
		"639-2/B": "",
	},
	sq: {
		family: "Indo-European",
		name: "Albanian",
		nativeName: "Shqip",
		"639-1": "sq",
		"639-2": "sqi",
		"639-2/B": "alb",
	},
	am: {
		family: "Afro-Asiatic",
		name: "Amharic",
		nativeName: "አማርኛ",
		"639-1": "am",
		"639-2": "amh",
		"639-2/B": "",
	},
	ar: {
		family: "Afro-Asiatic",
		name: "Arabic",
		nativeName: "العربية",
		"639-1": "ar",
		"639-2": "ara",
		"639-2/B": "",
	},
	an: {
		family: "Indo-European",
		name: "Aragonese",
		nativeName: "Aragonés",
		"639-1": "an",
		"639-2": "arg",
		"639-2/B": "",
	},
	hy: {
		family: "Indo-European",
		name: "Armenian",
		nativeName: "Հայերեն",
		"639-1": "hy",
		"639-2": "hye",
		"639-2/B": "arm",
	},
	as: {
		family: "Indo-European",
		name: "Assamese",
		nativeName: "অসমীয়া",
		"639-1": "as",
		"639-2": "asm",
		"639-2/B": "",
	},
	av: {
		family: "Northeast Caucasian",
		name: "Avaric",
		nativeName: "Авар мацӀ, магӀарул мацӀ",
		"639-1": "av",
		"639-2": "ava",
		"639-2/B": "",
	},
	ae: {
		family: "Indo-European",
		name: "Avestan",
		nativeName: "Avesta",
		"639-1": "ae",
		"639-2": "ave",
		"639-2/B": "",
	},
	ay: {
		family: "Aymaran",
		name: "Aymara",
		nativeName: "Aymar aru",
		"639-1": "ay",
		"639-2": "aym",
		"639-2/B": "",
	},
	az: {
		family: "Turkic",
		name: "Azerbaijani",
		nativeName: "Azərbaycan dili",
		"639-1": "az",
		"639-2": "aze",
		"639-2/B": "",
	},
	bm: {
		family: "Niger–Congo",
		name: "Bambara",
		nativeName: "Bamanankan",
		"639-1": "bm",
		"639-2": "bam",
		"639-2/B": "",
	},
	ba: {
		family: "Turkic",
		name: "Bashkir",
		nativeName: "Башҡорт теле",
		"639-1": "ba",
		"639-2": "bak",
		"639-2/B": "",
	},
	eu: {
		family: "Language isolate",
		name: "Basque",
		nativeName: "Euskara, euskera",
		"639-1": "eu",
		"639-2": "eus",
		"639-2/B": "baq",
	},
	be: {
		family: "Indo-European",
		name: "Belarusian",
		nativeName: "Беларуская мова",
		"639-1": "be",
		"639-2": "bel",
		"639-2/B": "",
	},
	bn: {
		family: "Indo-European",
		name: "Bengali, Bangla",
		nativeName: "বাংলা",
		"639-1": "bn",
		"639-2": "ben",
		"639-2/B": "",
	},
	bh: {
		family: "Indo-European",
		name: "Bihari",
		nativeName: "भोजपुरी",
		"639-1": "bh",
		"639-2": "bih",
		"639-2/B": "",
	},
	bi: {
		family: "Creole",
		name: "Bislama",
		nativeName: "Bislama",
		"639-1": "bi",
		"639-2": "bis",
		"639-2/B": "",
	},
	bs: {
		family: "Indo-European",
		name: "Bosnian",
		nativeName: "Bosanski jezik",
		"639-1": "bs",
		"639-2": "bos",
		"639-2/B": "",
	},
	br: {
		family: "Indo-European",
		name: "Breton",
		nativeName: "Brezhoneg",
		"639-1": "br",
		"639-2": "bre",
		"639-2/B": "",
	},
	bg: {
		family: "Indo-European",
		name: "Bulgarian",
		nativeName: "Български език",
		"639-1": "bg",
		"639-2": "bul",
		"639-2/B": "",
	},
	my: {
		family: "Sino-Tibetan",
		name: "Burmese",
		nativeName: "ဗမာစာ",
		"639-1": "my",
		"639-2": "mya",
		"639-2/B": "bur",
	},
	ca: {
		family: "Indo-European",
		name: "Catalan",
		nativeName: "Català",
		"639-1": "ca",
		"639-2": "cat",
		"639-2/B": "",
	},
	ch: {
		family: "Austronesian",
		name: "Chamorro",
		nativeName: "Chamoru",
		"639-1": "ch",
		"639-2": "cha",
		"639-2/B": "",
	},
	ce: {
		family: "Northeast Caucasian",
		name: "Chechen",
		nativeName: "Нохчийн мотт",
		"639-1": "ce",
		"639-2": "che",
		"639-2/B": "",
	},
	ny: {
		family: "Niger–Congo",
		name: "Chichewa, Chewa, Nyanja",
		nativeName: "ChiCheŵa, chinyanja",
		"639-1": "ny",
		"639-2": "nya",
		"639-2/B": "",
	},
	zh: {
		family: "Sino-Tibetan",
		name: "Chinese",
		nativeName: "中文 (Zhōngwén), 汉语, 漢語",
		"639-1": "zh",
		"639-2": "zho",
		"639-2/B": "chi",
	},
	cv: {
		family: "Turkic",
		name: "Chuvash",
		nativeName: "Чӑваш чӗлхи",
		"639-1": "cv",
		"639-2": "chv",
		"639-2/B": "",
	},
	kw: {
		family: "Indo-European",
		name: "Cornish",
		nativeName: "Kernewek",
		"639-1": "kw",
		"639-2": "cor",
		"639-2/B": "",
	},
	co: {
		family: "Indo-European",
		name: "Corsican",
		nativeName: "Corsu, lingua corsa",
		"639-1": "co",
		"639-2": "cos",
		"639-2/B": "",
	},
	cr: {
		family: "Algonquian",
		name: "Cree",
		nativeName: "ᓀᐦᐃᔭᐍᐏᐣ",
		"639-1": "cr",
		"639-2": "cre",
		"639-2/B": "",
	},
	hr: {
		family: "Indo-European",
		name: "Croatian",
		nativeName: "Hrvatski jezik",
		"639-1": "hr",
		"639-2": "hrv",
		"639-2/B": "",
	},
	cs: {
		family: "Indo-European",
		name: "Czech",
		nativeName: "Čeština, český jazyk",
		"639-1": "cs",
		"639-2": "ces",
		"639-2/B": "cze",
	},
	da: {
		family: "Indo-European",
		name: "Danish",
		nativeName: "Dansk",
		"639-1": "da",
		"639-2": "dan",
		"639-2/B": "",
	},
	dv: {
		family: "Indo-European",
		name: "Divehi, Dhivehi, Maldivian",
		nativeName: "ދިވެހި",
		"639-1": "dv",
		"639-2": "div",
		"639-2/B": "",
	},
	nl: {
		family: "Indo-European",
		name: "Dutch",
		nativeName: "Nederlands, Vlaams",
		"639-1": "nl",
		"639-2": "nld",
		"639-2/B": "dut",
	},
	dz: {
		family: "Sino-Tibetan",
		name: "Dzongkha",
		nativeName: "རྫོང་ཁ",
		"639-1": "dz",
		"639-2": "dzo",
		"639-2/B": "",
	},
	en: {
		family: "Indo-European",
		name: "English",
		nativeName: "English",
		"639-1": "en",
		"639-2": "eng",
		"639-2/B": "",
	},
	eo: {
		family: "Constructed",
		name: "Esperanto",
		nativeName: "Esperanto",
		"639-1": "eo",
		"639-2": "epo",
		"639-2/B": "",
	},
	et: {
		family: "Uralic",
		name: "Estonian",
		nativeName: "Eesti, eesti keel",
		"639-1": "et",
		"639-2": "est",
		"639-2/B": "",
	},
	ee: {
		family: "Niger–Congo",
		name: "Ewe",
		nativeName: "Eʋegbe",
		"639-1": "ee",
		"639-2": "ewe",
		"639-2/B": "",
	},
	fo: {
		family: "Indo-European",
		name: "Faroese",
		nativeName: "Føroyskt",
		"639-1": "fo",
		"639-2": "fao",
		"639-2/B": "",
	},
	fj: {
		family: "Austronesian",
		name: "Fijian",
		nativeName: "Vosa Vakaviti",
		"639-1": "fj",
		"639-2": "fij",
		"639-2/B": "",
	},
	fi: {
		family: "Uralic",
		name: "Finnish",
		nativeName: "Suomi, suomen kieli",
		"639-1": "fi",
		"639-2": "fin",
		"639-2/B": "",
	},
	fr: {
		family: "Indo-European",
		name: "French",
		nativeName: "Français, langue française",
		"639-1": "fr",
		"639-2": "fra",
		"639-2/B": "fre",
	},
	ff: {
		family: "Niger–Congo",
		name: "Fula, Fulah, Pulaar, Pular",
		nativeName: "Fulfulde, Pulaar, Pular",
		"639-1": "ff",
		"639-2": "ful",
		"639-2/B": "",
	},
	gl: {
		family: "Indo-European",
		name: "Galician",
		nativeName: "Galego",
		"639-1": "gl",
		"639-2": "glg",
		"639-2/B": "",
	},
	ka: {
		family: "South Caucasian",
		name: "Georgian",
		nativeName: "Ქართული",
		"639-1": "ka",
		"639-2": "kat",
		"639-2/B": "geo",
	},
	de: {
		family: "Indo-European",
		name: "German",
		nativeName: "Deutsch",
		"639-1": "de",
		"639-2": "deu",
		"639-2/B": "ger",
	},
	el: {
		family: "Indo-European",
		name: "Greek (modern)",
		nativeName: "Ελληνικά",
		"639-1": "el",
		"639-2": "ell",
		"639-2/B": "gre",
	},
	gn: {
		family: "Tupian",
		name: "Guaraní",
		nativeName: "Avañe'ẽ",
		"639-1": "gn",
		"639-2": "grn",
		"639-2/B": "",
	},
	gu: {
		family: "Indo-European",
		name: "Gujarati",
		nativeName: "ગુજરાતી",
		"639-1": "gu",
		"639-2": "guj",
		"639-2/B": "",
	},
	ht: {
		family: "Creole",
		name: "Haitian, Haitian Creole",
		nativeName: "Kreyòl ayisyen",
		"639-1": "ht",
		"639-2": "hat",
		"639-2/B": "",
	},
	ha: {
		family: "Afro-Asiatic",
		name: "Hausa",
		nativeName: "(Hausa) هَوُسَ",
		"639-1": "ha",
		"639-2": "hau",
		"639-2/B": "",
	},
	he: {
		family: "Afro-Asiatic",
		name: "Hebrew (modern)",
		nativeName: "עברית",
		"639-1": "he",
		"639-2": "heb",
		"639-2/B": "",
	},
	hz: {
		family: "Niger–Congo",
		name: "Herero",
		nativeName: "Otjiherero",
		"639-1": "hz",
		"639-2": "her",
		"639-2/B": "",
	},
	hi: {
		family: "Indo-European",
		name: "Hindi",
		nativeName: "हिन्दी, हिंदी",
		"639-1": "hi",
		"639-2": "hin",
		"639-2/B": "",
	},
	ho: {
		family: "Austronesian",
		name: "Hiri Motu",
		nativeName: "Hiri Motu",
		"639-1": "ho",
		"639-2": "hmo",
		"639-2/B": "",
	},
	hu: {
		family: "Uralic",
		name: "Hungarian",
		nativeName: "Magyar",
		"639-1": "hu",
		"639-2": "hun",
		"639-2/B": "",
	},
	ia: {
		family: "Constructed",
		name: "Interlingua",
		nativeName: "Interlingua",
		"639-1": "ia",
		"639-2": "ina",
		"639-2/B": "",
	},
	id: {
		family: "Austronesian",
		name: "Indonesian",
		nativeName: "Bahasa Indonesia",
		"639-1": "id",
		"639-2": "ind",
		"639-2/B": "",
	},
	ie: {
		family: "Constructed",
		name: "Interlingue",
		nativeName: "Originally called Occidental; then Interlingue after WWII",
		"639-1": "ie",
		"639-2": "ile",
		"639-2/B": "",
	},
	ga: {
		family: "Indo-European",
		name: "Irish",
		nativeName: "Gaeilge",
		"639-1": "ga",
		"639-2": "gle",
		"639-2/B": "",
	},
	ig: {
		family: "Niger–Congo",
		name: "Igbo",
		nativeName: "Asụsụ Igbo",
		"639-1": "ig",
		"639-2": "ibo",
		"639-2/B": "",
	},
	ik: {
		family: "Eskimo–Aleut",
		name: "Inupiaq",
		nativeName: "Iñupiaq, Iñupiatun",
		"639-1": "ik",
		"639-2": "ipk",
		"639-2/B": "",
	},
	io: {
		family: "Constructed",
		name: "Ido",
		nativeName: "Ido",
		"639-1": "io",
		"639-2": "ido",
		"639-2/B": "",
	},
	is: {
		family: "Indo-European",
		name: "Icelandic",
		nativeName: "Íslenska",
		"639-1": "is",
		"639-2": "isl",
		"639-2/B": "ice",
	},
	it: {
		family: "Indo-European",
		name: "Italian",
		nativeName: "Italiano",
		"639-1": "it",
		"639-2": "ita",
		"639-2/B": "",
	},
	iu: {
		family: "Eskimo–Aleut",
		name: "Inuktitut",
		nativeName: "ᐃᓄᒃᑎᑐᑦ",
		"639-1": "iu",
		"639-2": "iku",
		"639-2/B": "",
	},
	ja: {
		family: "Japonic",
		name: "Japanese",
		nativeName: "日本語 (にほんご)",
		"639-1": "ja",
		"639-2": "jpn",
		"639-2/B": "",
	},
	jv: {
		family: "Austronesian",
		name: "Javanese",
		nativeName: "ꦧꦱꦗꦮ, Basa Jawa",
		"639-1": "jv",
		"639-2": "jav",
		"639-2/B": "",
	},
	kl: {
		family: "Eskimo–Aleut",
		name: "Kalaallisut, Greenlandic",
		nativeName: "Kalaallisut, kalaallit oqaasii",
		"639-1": "kl",
		"639-2": "kal",
		"639-2/B": "",
	},
	kn: {
		family: "Dravidian",
		name: "Kannada",
		nativeName: "ಕನ್ನಡ",
		"639-1": "kn",
		"639-2": "kan",
		"639-2/B": "",
	},
	kr: {
		family: "Nilo-Saharan",
		name: "Kanuri",
		nativeName: "Kanuri",
		"639-1": "kr",
		"639-2": "kau",
		"639-2/B": "",
	},
	ks: {
		family: "Indo-European",
		name: "Kashmiri",
		nativeName: "कश्मीरी, كشميري‎",
		"639-1": "ks",
		"639-2": "kas",
		"639-2/B": "",
	},
	kk: {
		family: "Turkic",
		name: "Kazakh",
		nativeName: "Қазақ тілі",
		"639-1": "kk",
		"639-2": "kaz",
		"639-2/B": "",
	},
	km: {
		family: "Austroasiatic",
		name: "Khmer",
		nativeName: "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
		"639-1": "km",
		"639-2": "khm",
		"639-2/B": "",
	},
	ki: {
		family: "Niger–Congo",
		name: "Kikuyu, Gikuyu",
		nativeName: "Gĩkũyũ",
		"639-1": "ki",
		"639-2": "kik",
		"639-2/B": "",
	},
	rw: {
		family: "Niger–Congo",
		name: "Kinyarwanda",
		nativeName: "Ikinyarwanda",
		"639-1": "rw",
		"639-2": "kin",
		"639-2/B": "",
	},
	ky: {
		family: "Turkic",
		name: "Kyrgyz",
		nativeName: "Кыргызча, Кыргыз тили",
		"639-1": "ky",
		"639-2": "kir",
		"639-2/B": "",
	},
	kv: {
		family: "Uralic",
		name: "Komi",
		nativeName: "Коми кыв",
		"639-1": "kv",
		"639-2": "kom",
		"639-2/B": "",
	},
	kg: {
		family: "Niger–Congo",
		name: "Kongo",
		nativeName: "Kikongo",
		"639-1": "kg",
		"639-2": "kon",
		"639-2/B": "",
	},
	ko: {
		family: "Koreanic",
		name: "Korean",
		nativeName: "한국어",
		"639-1": "ko",
		"639-2": "kor",
		"639-2/B": "",
	},
	ku: {
		family: "Indo-European",
		name: "Kurdish",
		nativeName: "Kurdî, كوردی‎",
		"639-1": "ku",
		"639-2": "kur",
		"639-2/B": "",
	},
	kj: {
		family: "Niger–Congo",
		name: "Kwanyama, Kuanyama",
		nativeName: "Kuanyama",
		"639-1": "kj",
		"639-2": "kua",
		"639-2/B": "",
	},
	la: {
		family: "Indo-European",
		name: "Latin",
		nativeName: "Latine, lingua latina",
		"639-1": "la",
		"639-2": "lat",
		"639-2/B": "",
	},
	lb: {
		family: "Indo-European",
		name: "Luxembourgish, Letzeburgesch",
		nativeName: "Lëtzebuergesch",
		"639-1": "lb",
		"639-2": "ltz",
		"639-2/B": "",
	},
	lg: {
		family: "Niger–Congo",
		name: "Ganda",
		nativeName: "Luganda",
		"639-1": "lg",
		"639-2": "lug",
		"639-2/B": "",
	},
	li: {
		family: "Indo-European",
		name: "Limburgish, Limburgan, Limburger",
		nativeName: "Limburgs",
		"639-1": "li",
		"639-2": "lim",
		"639-2/B": "",
	},
	ln: {
		family: "Niger–Congo",
		name: "Lingala",
		nativeName: "Lingála",
		"639-1": "ln",
		"639-2": "lin",
		"639-2/B": "",
	},
	lo: {
		family: "Tai–Kadai",
		name: "Lao",
		nativeName: "ພາສາລາວ",
		"639-1": "lo",
		"639-2": "lao",
		"639-2/B": "",
	},
	lt: {
		family: "Indo-European",
		name: "Lithuanian",
		nativeName: "Lietuvių kalba",
		"639-1": "lt",
		"639-2": "lit",
		"639-2/B": "",
	},
	lu: {
		family: "Niger–Congo",
		name: "Luba-Katanga",
		nativeName: "Tshiluba",
		"639-1": "lu",
		"639-2": "lub",
		"639-2/B": "",
	},
	lv: {
		family: "Indo-European",
		name: "Latvian",
		nativeName: "Latviešu valoda",
		"639-1": "lv",
		"639-2": "lav",
		"639-2/B": "",
	},
	gv: {
		family: "Indo-European",
		name: "Manx",
		nativeName: "Gaelg, Gailck",
		"639-1": "gv",
		"639-2": "glv",
		"639-2/B": "",
	},
	mk: {
		family: "Indo-European",
		name: "Macedonian",
		nativeName: "Makedonski jazik",
		"639-1": "mk",
		"639-2": "mkd",
		"639-2/B": "mac",
	},
	mg: {
		family: "Austronesian",
		name: "Malagasy",
		nativeName: "Fiteny malagasy",
		"639-1": "mg",
		"639-2": "mlg",
		"639-2/B": "",
	},
	ms: {
		family: "Austronesian",
		name: "Malay",
		nativeName: "Bahasa Melayu, بهاس ملايو‎",
		"639-1": "ms",
		"639-2": "msa",
		"639-2/B": "may",
	},
	ml: {
		family: "Dravidian",
		name: "Malayalam",
		nativeName: "മലയാളം",
		"639-1": "ml",
		"639-2": "mal",
		"639-2/B": "",
	},
	mt: {
		family: "Afro-Asiatic",
		name: "Maltese",
		nativeName: "Malti",
		"639-1": "mt",
		"639-2": "mlt",
		"639-2/B": "",
	},
	mi: {
		family: "Austronesian",
		name: "Māori",
		nativeName: "Te reo Māori",
		"639-1": "mi",
		"639-2": "mri",
		"639-2/B": "mao",
	},
	mr: {
		family: "Indo-European",
		name: "Marathi (Marāṭhī)",
		nativeName: "मराठी",
		"639-1": "mr",
		"639-2": "mar",
		"639-2/B": "",
	},
	mh: {
		family: "Austronesian",
		name: "Marshallese",
		nativeName: "Kajin M̧ajeļ",
		"639-1": "mh",
		"639-2": "mah",
		"639-2/B": "",
	},
	mn: {
		family: "Mongolic",
		name: "Mongolian",
		nativeName: "Монгол хэл",
		"639-1": "mn",
		"639-2": "mon",
		"639-2/B": "",
	},
	na: {
		family: "Austronesian",
		name: "Nauruan",
		nativeName: "Dorerin Naoero",
		"639-1": "na",
		"639-2": "nau",
		"639-2/B": "",
	},
	nv: {
		family: "Dené–Yeniseian",
		name: "Navajo, Navaho",
		nativeName: "Diné bizaad",
		"639-1": "nv",
		"639-2": "nav",
		"639-2/B": "",
	},
	nd: {
		family: "Niger–Congo",
		name: "Northern Ndebele",
		nativeName: "IsiNdebele",
		"639-1": "nd",
		"639-2": "nde",
		"639-2/B": "",
	},
	ne: {
		family: "Indo-European",
		name: "Nepali",
		nativeName: "नेपाली",
		"639-1": "ne",
		"639-2": "nep",
		"639-2/B": "",
	},
	ng: {
		family: "Niger–Congo",
		name: "Ndonga",
		nativeName: "Owambo",
		"639-1": "ng",
		"639-2": "ndo",
		"639-2/B": "",
	},
	nb: {
		family: "Indo-European",
		name: "Norwegian Bokmål",
		nativeName: "Norsk bokmål",
		"639-1": "nb",
		"639-2": "nob",
		"639-2/B": "",
	},
	nn: {
		family: "Indo-European",
		name: "Norwegian Nynorsk",
		nativeName: "Norsk nynorsk",
		"639-1": "nn",
		"639-2": "nno",
		"639-2/B": "",
	},
	no: {
		family: "Indo-European",
		name: "Norwegian",
		nativeName: "Norsk",
		"639-1": "no",
		"639-2": "nor",
		"639-2/B": "",
	},
	ii: {
		family: "Sino-Tibetan",
		name: "Nuosu",
		nativeName: "ꆈꌠ꒿ Nuosuhxop",
		"639-1": "ii",
		"639-2": "iii",
		"639-2/B": "",
	},
	nr: {
		family: "Niger–Congo",
		name: "Southern Ndebele",
		nativeName: "IsiNdebele",
		"639-1": "nr",
		"639-2": "nbl",
		"639-2/B": "",
	},
	oc: {
		family: "Indo-European",
		name: "Occitan",
		nativeName: "Occitan, lenga d'òc",
		"639-1": "oc",
		"639-2": "oci",
		"639-2/B": "",
	},
	oj: {
		family: "Algonquian",
		name: "Ojibwe, Ojibwa",
		nativeName: "ᐊᓂᔑᓈᐯᒧᐎᓐ",
		"639-1": "oj",
		"639-2": "oji",
		"639-2/B": "",
	},
	cu: {
		family: "Indo-European",
		name: "Old Church Slavonic, Church Slavonic, Old Bulgarian",
		nativeName: "Ѩзыкъ словѣньскъ",
		"639-1": "cu",
		"639-2": "chu",
		"639-2/B": "",
	},
	om: {
		family: "Afro-Asiatic",
		name: "Oromo",
		nativeName: "Afaan Oromoo",
		"639-1": "om",
		"639-2": "orm",
		"639-2/B": "",
	},
	or: {
		family: "Indo-European",
		name: "Oriya",
		nativeName: "ଓଡ଼ିଆ",
		"639-1": "or",
		"639-2": "ori",
		"639-2/B": "",
	},
	os: {
		family: "Indo-European",
		name: "Ossetian, Ossetic",
		nativeName: "Ирон æвзаг",
		"639-1": "os",
		"639-2": "oss",
		"639-2/B": "",
	},
	pa: {
		family: "Indo-European",
		name: "(Eastern) Punjabi",
		nativeName: "ਪੰਜਾਬੀ",
		"639-1": "pa",
		"639-2": "pan",
		"639-2/B": "",
	},
	pi: {
		family: "Indo-European",
		name: "Pāli",
		nativeName: "पाऴि",
		"639-1": "pi",
		"639-2": "pli",
		"639-2/B": "",
	},
	fa: {
		family: "Indo-European",
		name: "Persian (Farsi)",
		nativeName: "فارسی",
		"639-1": "fa",
		"639-2": "fas",
		"639-2/B": "per",
	},
	pl: {
		family: "Indo-European",
		name: "Polish",
		nativeName: "Język polski, polszczyzna",
		"639-1": "pl",
		"639-2": "pol",
		"639-2/B": "",
	},
	ps: {
		family: "Indo-European",
		name: "Pashto, Pushto",
		nativeName: "پښتو",
		"639-1": "ps",
		"639-2": "pus",
		"639-2/B": "",
	},
	pt: {
		family: "Indo-European",
		name: "Portuguese",
		nativeName: "Português",
		"639-1": "pt",
		"639-2": "por",
		"639-2/B": "",
	},
	qu: {
		family: "Quechuan",
		name: "Quechua",
		nativeName: "Runa Simi, Kichwa",
		"639-1": "qu",
		"639-2": "que",
		"639-2/B": "",
	},
	rm: {
		family: "Indo-European",
		name: "Romansh",
		nativeName: "Rumantsch grischun",
		"639-1": "rm",
		"639-2": "roh",
		"639-2/B": "",
	},
	rn: {
		family: "Niger–Congo",
		name: "Kirundi",
		nativeName: "Ikirundi",
		"639-1": "rn",
		"639-2": "run",
		"639-2/B": "",
	},
	ro: {
		family: "Indo-European",
		name: "Romanian",
		nativeName: "Română",
		"639-1": "ro",
		"639-2": "ron",
		"639-2/B": "rum",
	},
	ru: {
		family: "Indo-European",
		name: "Russian",
		nativeName: "Русский",
		"639-1": "ru",
		"639-2": "rus",
		"639-2/B": "",
	},
	sa: {
		family: "Indo-European",
		name: "Sanskrit (Saṁskṛta)",
		nativeName: "संस्कृतम्",
		"639-1": "sa",
		"639-2": "san",
		"639-2/B": "",
	},
	sc: {
		family: "Indo-European",
		name: "Sardinian",
		nativeName: "Sardu",
		"639-1": "sc",
		"639-2": "srd",
		"639-2/B": "",
	},
	sd: {
		family: "Indo-European",
		name: "Sindhi",
		nativeName: "सिन्धी, سنڌي، سندھی‎",
		"639-1": "sd",
		"639-2": "snd",
		"639-2/B": "",
	},
	se: {
		family: "Uralic",
		name: "Northern Sami",
		nativeName: "Davvisámegiella",
		"639-1": "se",
		"639-2": "sme",
		"639-2/B": "",
	},
	sm: {
		family: "Austronesian",
		name: "Samoan",
		nativeName: "Gagana fa'a Samoa",
		"639-1": "sm",
		"639-2": "smo",
		"639-2/B": "",
	},
	sg: {
		family: "Creole",
		name: "Sango",
		nativeName: "Yângâ tî sängö",
		"639-1": "sg",
		"639-2": "sag",
		"639-2/B": "",
	},
	sr: {
		family: "Indo-European",
		name: "Serbian",
		nativeName: "Srpski jezik",
		"639-1": "sr",
		"639-2": "srp",
		"639-2/B": "",
	},
	gd: {
		family: "Indo-European",
		name: "Scottish Gaelic, Gaelic",
		nativeName: "Gàidhlig",
		"639-1": "gd",
		"639-2": "gla",
		"639-2/B": "",
	},
	sn: {
		family: "Niger–Congo",
		name: "Shona",
		nativeName: "ChiShona",
		"639-1": "sn",
		"639-2": "sna",
		"639-2/B": "",
	},
	si: {
		family: "Indo-European",
		name: "Sinhalese, Sinhala",
		nativeName: "සිංහල",
		"639-1": "si",
		"639-2": "sin",
		"639-2/B": "",
	},
	sk: {
		family: "Indo-European",
		name: "Slovak",
		nativeName: "Slovenčina, slovenský jazyk",
		"639-1": "sk",
		"639-2": "slk",
		"639-2/B": "slo",
	},
	sl: {
		family: "Indo-European",
		name: "Slovene",
		nativeName: "Slovenščina",
		"639-1": "sl",
		"639-2": "slv",
		"639-2/B": "",
	},
	so: {
		family: "Afro-Asiatic",
		name: "Somali",
		nativeName: "Soomaaliga, af Soomaali",
		"639-1": "so",
		"639-2": "som",
		"639-2/B": "",
	},
	st: {
		family: "Niger–Congo",
		name: "Southern Sotho",
		nativeName: "Sesotho",
		"639-1": "st",
		"639-2": "sot",
		"639-2/B": "",
	},
	es: {
		family: "Indo-European",
		name: "Spanish",
		nativeName: "Español",
		"639-1": "es",
		"639-2": "spa",
		"639-2/B": "",
	},
	su: {
		family: "Austronesian",
		name: "Sundanese",
		nativeName: "Basa Sunda",
		"639-1": "su",
		"639-2": "sun",
		"639-2/B": "",
	},
	sw: {
		family: "Niger–Congo",
		name: "Swahili",
		nativeName: "Kiswahili",
		"639-1": "sw",
		"639-2": "swa",
		"639-2/B": "",
	},
	ss: {
		family: "Niger–Congo",
		name: "Swati",
		nativeName: "SiSwati",
		"639-1": "ss",
		"639-2": "ssw",
		"639-2/B": "",
	},
	sv: {
		family: "Indo-European",
		name: "Swedish",
		nativeName: "Svenska",
		"639-1": "sv",
		"639-2": "swe",
		"639-2/B": "",
	},
	ta: {
		family: "Dravidian",
		name: "Tamil",
		nativeName: "தமிழ்",
		"639-1": "ta",
		"639-2": "tam",
		"639-2/B": "",
	},
	te: {
		family: "Dravidian",
		name: "Telugu",
		nativeName: "తెలుగు",
		"639-1": "te",
		"639-2": "tel",
		"639-2/B": "",
	},
	tg: {
		family: "Indo-European",
		name: "Tajik",
		nativeName: "Тоҷикӣ, toçikī, تاجیکی‎",
		"639-1": "tg",
		"639-2": "tgk",
		"639-2/B": "",
	},
	th: {
		family: "Tai–Kadai",
		name: "Thai",
		nativeName: "ไทย",
		"639-1": "th",
		"639-2": "tha",
		"639-2/B": "",
	},
	ti: {
		family: "Afro-Asiatic",
		name: "Tigrinya",
		nativeName: "ትግርኛ",
		"639-1": "ti",
		"639-2": "tir",
		"639-2/B": "",
	},
	bo: {
		family: "Sino-Tibetan",
		name: "Tibetan Standard, Tibetan, Central",
		nativeName: "བོད་ཡིག",
		"639-1": "bo",
		"639-2": "bod",
		"639-2/B": "tib",
	},
	tk: {
		family: "Turkic",
		name: "Turkmen",
		nativeName: "Türkmen, Түркмен",
		"639-1": "tk",
		"639-2": "tuk",
		"639-2/B": "",
	},
	tl: {
		family: "Austronesian",
		name: "Tagalog",
		nativeName: "Wikang Tagalog",
		"639-1": "tl",
		"639-2": "tgl",
		"639-2/B": "",
	},
	tn: {
		family: "Niger–Congo",
		name: "Tswana",
		nativeName: "Setswana",
		"639-1": "tn",
		"639-2": "tsn",
		"639-2/B": "",
	},
	to: {
		family: "Austronesian",
		name: "Tonga (Tonga Islands)",
		nativeName: "Faka Tonga",
		"639-1": "to",
		"639-2": "ton",
		"639-2/B": "",
	},
	tr: {
		family: "Turkic",
		name: "Turkish",
		nativeName: "Türkçe",
		"639-1": "tr",
		"639-2": "tur",
		"639-2/B": "",
	},
	ts: {
		family: "Niger–Congo",
		name: "Tsonga",
		nativeName: "Xitsonga",
		"639-1": "ts",
		"639-2": "tso",
		"639-2/B": "",
	},
	tt: {
		family: "Turkic",
		name: "Tatar",
		nativeName: "Татар теле, tatar tele",
		"639-1": "tt",
		"639-2": "tat",
		"639-2/B": "",
	},
	tw: {
		family: "Niger–Congo",
		name: "Twi",
		nativeName: "Twi",
		"639-1": "tw",
		"639-2": "twi",
		"639-2/B": "",
	},
	ty: {
		family: "Austronesian",
		name: "Tahitian",
		nativeName: "Reo Tahiti",
		"639-1": "ty",
		"639-2": "tah",
		"639-2/B": "",
	},
	ug: {
		family: "Turkic",
		name: "Uyghur",
		nativeName: "ئۇيغۇرچە‎, Uyghurche",
		"639-1": "ug",
		"639-2": "uig",
		"639-2/B": "",
	},
	uk: {
		family: "Indo-European",
		name: "Ukrainian",
		nativeName: "Українська",
		"639-1": "uk",
		"639-2": "ukr",
		"639-2/B": "",
	},
	ur: {
		family: "Indo-European",
		name: "Urdu",
		nativeName: "اردو",
		"639-1": "ur",
		"639-2": "urd",
		"639-2/B": "",
	},
	uz: {
		family: "Turkic",
		name: "Uzbek",
		nativeName: "Oʻzbek, Ўзбек, أۇزبېك‎",
		"639-1": "uz",
		"639-2": "uzb",
		"639-2/B": "",
	},
	ve: {
		family: "Niger–Congo",
		name: "Venda",
		nativeName: "Tshivenḓa",
		"639-1": "ve",
		"639-2": "ven",
		"639-2/B": "",
	},
	vi: {
		family: "Austroasiatic",
		name: "Vietnamese",
		nativeName: "Tiếng Việt",
		"639-1": "vi",
		"639-2": "vie",
		"639-2/B": "",
	},
	vo: {
		family: "Constructed",
		name: "Volapük",
		nativeName: "Volapük",
		"639-1": "vo",
		"639-2": "vol",
		"639-2/B": "",
	},
	wa: {
		family: "Indo-European",
		name: "Walloon",
		nativeName: "Walon",
		"639-1": "wa",
		"639-2": "wln",
		"639-2/B": "",
	},
	cy: {
		family: "Indo-European",
		name: "Welsh",
		nativeName: "Cymraeg",
		"639-1": "cy",
		"639-2": "cym",
		"639-2/B": "wel",
	},
	wo: {
		family: "Niger–Congo",
		name: "Wolof",
		nativeName: "Wollof",
		"639-1": "wo",
		"639-2": "wol",
		"639-2/B": "",
	},
	fy: {
		family: "Indo-European",
		name: "Western Frisian",
		nativeName: "Frysk",
		"639-1": "fy",
		"639-2": "fry",
		"639-2/B": "",
	},
	xh: {
		family: "Niger–Congo",
		name: "Xhosa",
		nativeName: "IsiXhosa",
		"639-1": "xh",
		"639-2": "xho",
		"639-2/B": "",
	},
	yi: {
		family: "Indo-European",
		name: "Yiddish",
		nativeName: "ייִדיש",
		"639-1": "yi",
		"639-2": "yid",
		"639-2/B": "",
	},
	yo: {
		family: "Niger–Congo",
		name: "Yoruba",
		nativeName: "Yorùbá",
		"639-1": "yo",
		"639-2": "yor",
		"639-2/B": "",
	},
	za: {
		family: "Tai–Kadai",
		name: "Zhuang, Chuang",
		nativeName: "Saɯ cueŋƅ, Saw cuengh",
		"639-1": "za",
		"639-2": "zha",
		"639-2/B": "",
	},
	zu: {
		family: "Niger–Congo",
		name: "Zulu",
		nativeName: "IsiZulu",
		"639-1": "zu",
		"639-2": "zul",
		"639-2/B": "",
	},
};

const ISO639to3166: any = {
	aa: "dj",
	af: "za",
	ak: "gh",
	sq: "al",
	am: "et",
	hy: "am",
	az: "az",
	bm: "ml",
	be: "by",
	bn: "bd",
	bi: "vu",
	bs: "ba",
	bg: "bg",
	my: "mm",
	ca: "ad",
	zh: "cn",
	hr: "hr",
	cs: "cz",
	da: "dk",
	dv: "mv",
	nl: "nl",
	dz: "bt",
	en: "gb",
	et: "ee",
	fj: "fj",
	fil: "ph",
	fi: "fi",
	fr: "fr",
	gaa: "gh",
	ka: "ge",
	de: "de",
	el: "gr",
	gu: "in",
	ht: "ht",
	he: "il",
	hi: "in",
	ho: "pg",
	hu: "hu",
	is: "is",
	ig: "ng",
	id: "id",
	ga: "ie",
	it: "it",
	ja: "jp",
	kr: "ne",
	kk: "kz",
	km: "kh",
	kmb: "ao",
	rw: "rw",
	kg: "cg",
	ko: "kr",
	kj: "ao",
	ku: "iq",
	ky: "kg",
	lo: "la",
	la: "va",
	lv: "lv",
	ln: "cg",
	lt: "lt",
	lu: "cd",
	lb: "lu",
	mk: "mk",
	mg: "mg",
	ms: "my",
	mt: "mt",
	mi: "nz",
	mh: "mh",
	mn: "mn",
	mos: "bf",
	ne: "np",
	nd: "zw",
	nso: "za",
	no: "no",
	nb: "no",
	nn: "no",
	ny: "mw",
	pap: "aw",
	ps: "af",
	fa: "ir",
	pl: "pl",
	pt: "pt",
	pa: "in",
	qu: "wh",
	ro: "ro",
	rm: "ch",
	rn: "bi",
	ru: "ru",
	sg: "cf",
	sr: "rs",
	srr: "sn",
	sn: "zw",
	si: "lk",
	sk: "sk",
	sl: "si",
	so: "so",
	snk: "sn",
	nr: "za",
	st: "ls",
	es: "es",
	ss: "sz",
	sv: "se",
	tl: "ph",
	tg: "tj",
	ta: "lk",
	te: "in",
	tet: "tl",
	th: "th",
	ti: "er",
	tpi: "pg",
	ts: "za",
	tn: "bw",
	tr: "tr",
	tk: "tm",
	uk: "ua",
	umb: "ao",
	ur: "pk",
	uz: "uz",
	ve: "za",
	vi: "vn",
	cy: "gb",
	wo: "sn",
	xh: "za",
	zu: "za",
};

export const getLangIconLocation = (isoName: string) => {
	return `${process.env.PUBLIC_URL}/img/lang/${ISO639to3166?.[isoName]}.svg`;
};
