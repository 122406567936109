import { useState } from "react";

export const DebugScreen = () => {
	const [images, setImages] = useState<string[]>([]);
	const [apiUrl, setApiUrl] = useState("");
	return (
		<div className="flex flex-col gap-10">
			API URL:{" "}
			<input
			className="p-2 rounded min-w-500px"
				type="text"
				value={apiUrl}
				onChange={(e) => setApiUrl(e.target.value)}
			/>
			<button
			className="rounded bg-white p-2"
				onClick={() => {
					fetch(apiUrl, {method:"POST"})
						.then((res) => res.json())
						.then((data) => {
							setImages(data.image_base64);
						});
				}}
			>send</button>
			out:
			{images.map((image) => (
				<img src={image} />
			))}
		</div>
	);
};
