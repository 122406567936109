import { FC, useRef, useState } from "react";
import { BasicCard } from "../components/BasicCard";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { selectTranslator } from "../redux/language/language.selector";
import { HTMLInputField, HTMLTextAreaField } from "../components/InputFields";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { LanguageDropdown } from "../components/LanguageDropdowns";
import { DefaultDropdown } from "../components/Dropdowns";
import {
	getLangIconLocation,
	ISOLanguages,
} from "../content/translations/country_codes";
import { PrimaryButton, PrimaryButtonLink } from "../components/Buttons";
import { startGenerateCard } from "../redux/cardgen/cardgen.actions";

const availableRecipientLanguages = ["sl", "en", "sr", "hr", "bs", "sq"];

export const InitialPrompt: FC = () => {
	const platformLanguage = useAppSelector(
		(state) => state.language.platformLanguage
	);
	const t = useAppSelector(selectTranslator);
	const [recipientLanguage, setRecipientLanguage] =
		useState(platformLanguage);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const onSubmitForm = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data: any = Object.fromEntries(
			new FormData(e.target as HTMLFormElement)
		);
		navigate("/card-improvement");
		dispatch(
			startGenerateCard(
				data["sender-name"],
				data["recipient-name"],
				data["prompt"],
				recipientLanguage
			)
		);
	};

	return (
		<BasicCard className="container">
			<form onSubmit={onSubmitForm}>
				<div>
					<Link
						to="/"
						className="mb-10 inline-flex flex-row items-center gap-3"
					>
						<FaArrowLeft /> <div>{t("platform-back")}</div>
					</Link>
				</div>
				<div className="row md:flex-row flex-col mb-6">
					<div className="col-12 md:col-6 lg:col-4">
						<HTMLInputField
							label={t("sender-name")}
							name="sender-name"
							elementProps={{ required: true }}
						/>
					</div>
					<div className="col-12 md:col-6 lg:col-5">
						<HTMLInputField
							label={t("recipient-name")}
							name="recipient-name"
							elementProps={{ required: true }}
						/>
					</div>
					<div className="col-12 lg:col-3">
						<DefaultDropdown
							fullWidth
							label={t("recipient-language-select")}
							options={availableRecipientLanguages.map(
								(lang) => ({
									id: lang,
									text: (ISOLanguages as any)[lang]
										.nativeName,
									iconSrc: getLangIconLocation(lang),
								})
							)}
							value={recipientLanguage}
							onChange={(e) => setRecipientLanguage(e as any)}
							right
						/>
					</div>
				</div>
				<div className="mb-10">
					<HTMLTextAreaField
						elementProps={{ rows: 5, required: true }}
						name="prompt"
						label={t("prompt-message")}
					/>
				</div>
				<div
					className="whitespace-pre-wrap text-nlb_gray_dark mb-6 html-translation"
					dangerouslySetInnerHTML={{
						__html: t("instructions-for-prompt"),
					}}
				></div>
				<div className="flex flex-row justify-end">
					<PrimaryButton>{t("platform-continue")}</PrimaryButton>
				</div>
			</form>
		</BasicCard>
	);
};
